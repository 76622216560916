
import Francais from '@uppy/locales/lib/fr_FR';
import Quill from 'quill';


export const alertClasses = {
    customClass: {
        container: 'swal__container',
        popup: 'swal__popup',
        header: 'swal__header',
        title: 'swal__title',
        closeButton: 'swal__closeButton',
        htmlContainer: 'swal__htmlContainer',
        input: 'field w-auto',
        actions: 'swal__actions',
        confirmButton: 'swal__confirmButton',
        denyButton: 'swal__denyButton'
    },
    closeButtonHtml: '<i class="icons-cross icons--lg "></i>',
    showClass: {
        popup: 'animate__animated animate__fadeInUp'
    },
    hideClass: {
        popup: 'animate__animated animate__fadeOutDown'
    }
};

export const dualListBoxConfig = {
    availableTitle:'Disponible(s)',
    selectedTitle: 'Sélectionné(s)',
    showAddButton: false,
    showRemoveButton: false,
    addAllButtonText: '<i class="icons-angle-right"></i>',
    removeAllButtonText: '<i class="icons-angle-left"></i>',
    enableDoubleClick: false,
    searchPlaceholder: 'Rechercher ...',
};

export async function copyToClipboard(textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
};

const dropMessage = `Glissez votre document ici ou`

export const uppyFr = {
    ...Francais, strings: {
        ...Francais.strings,
        ...{
            dropHereOr: dropMessage,
            dropHint: dropMessage,
            dropPasteBoth: dropMessage,
            dropPasteFiles: dropMessage,
            dropPasteFolders: dropMessage,
            dropPasteImportBoth: dropMessage,
            dropPasteImportFiles: dropMessage,
            dropPasteImportFolders: dropMessage,
            myDevice: 'Ajouter un document',
        }
    }
}

export const quillConfig = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [
                {
                    list: 'ordered'
                },
                {
                    list: 'bullet'
                }
            ],
            [
                {
                    color: []
                },
                {
                    background: []
                }
            ],
            ['clean']
        ]
    },
    placeholder: 'Entrer du texte',
    theme: 'snow'
};

export const resetForm = (selector, submit = false) => {

    selector.dataset.isreset = '1';

    selector.querySelectorAll('input,select,textarea,.ql-container').forEach((elm) => {

        const tagName = elm.tagName.toUpperCase();
        const type = elm.type ? elm.type.toLowerCase() : '';

        if (elm.classList.contains('uppy-input')) {

            if (elm.__uppy) {
                elm.__uppy.getFiles().forEach((file) => {
                    elm.__uppy.removeFile(file.id);
                });

                const dataTransfer = new DataTransfer();
                elm.files = dataTransfer.files;
            }

        } else if (tagName === 'SELECT' && elm.slim) {

            if (elm.getAttribute('multiple') !== null && elm.getAttribute('multiple') != 'false') {
                elm.slim.setSelected([]);
            } else {
                elm.slim.setSelected(elm.querySelector('option:first-child').value);
            }

        } else if (tagName === 'INPUT' && type === 'checkbox') {

            if (elm.classList.contains('filter__checkbox')) {
                if (elm.value == 'all') {
                    elm.checked = true;
                } else {
                    elm.checked = false;
                }
            } else {

                elm.checked = false;
                if (elm.classList.contains('toggle__input')) {
                    if (elm.nextElementSibling.querySelector('.toggle__label')) {
                        elm.nextElementSibling.querySelector('.toggle__label').innerText = 'Non';
                    }
                }
            }


        } else if (elm.classList.contains('ql-container')) {

            elm.__quill.setContents([]);

        } else {

            elm.value = '';

        }

    });
    setTimeout(() => {
        selector.dataset.isreset = '0';
        if (submit) {
            submit();
        }
    },100);
};

export const debounce = (func, timeout = 450) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const categorieStatutEDF = {
    all: [
        'Devis signé',
        'Facture',
        'Dossier finalisé',
        'Reçu back-office',
        'Transmis à EDF par back-office',
        'Reçu EDF',
        'Dossier en circulaire',
        'Circulaire terminée',
        'Prévalidé en attente',
        'Prévalidé',
        'Dossier en refus',
        'Refusé admin.',
        'Dossier abandonné',
        'Abandonné'
    ],
    en_ecart: [
        'Devis signé',
        'Facture',
        'Dossier finalisé',
        'Reçu back-office',
        'Transmis à EDF par back-office',
        'Dossier en circulaire'
    ],
    a_envoyer: [
        'Dossier finalisé',
        'Reçu back-office',
        'Transmis à EDF par back-office',
    ],
    en_cours: [
        'Devis signé',
        'Facture',
        'Dossier finalisé',
        'Reçu back-office',
        'Transmis à EDF par back-office',
        'Reçu EDF',
        'Circulaire terminée',
        'Prévalidé en attente',
    ],
    a_finaliser: [
        'Devis signé',
        'Facture',
    ],
    a_aaf:  [
        'Prévalidé'
    ],
    payes: [
        'Prévalidé'
    ]
}
